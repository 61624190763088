// @ts-check

import React, { useState } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import paths from "../paths";
import QuestionDescription from "../components/question-description";
import DeleteButton from "../components/practice-history-item/delete-button.js";
import ToHubButton from "../components/practice-history-item/to-hub-button.js";
import ChangeButton from "../components/practice-history-item/change-button.js";

export default function PracticeHistory() {
    const loadedData = /** @type {Array<import("../api").FemHistoryItem>} */ (useLoaderData());
    const [items, setItems] = useState(loadedData);
    const navigate = useNavigate();
    const statusToText = {
        "SOLVED": "Solved",
        "FAILED": "Couldn't solve",
        "DISMISSED": "Decided not to solve",
    };

    function HistoryItemContent({item}) {
        if (item.question) {
            return (
                <>
                    <QuestionDescription question={item.question} />
                    <p><small>{statusToText[item.status]} on {new Date(item.time).toLocaleString('en-US')}</small></p>
                </>
            );
        }
    }

    /**
     * 
     * @param {number} itemId 
     * @param {import("../api").FemHistoryItemStatus} newStatus 
     */
    function setItemStatus(itemId, newStatus) {
        setItems((oldItems) => {
            const index = oldItems.findIndex((item) => itemId === item.id);
            if (index === -1) {
                return oldItems;
            } else {
                const newItems = [...oldItems];
                newItems[index] = {...newItems[index], status: newStatus};
                return newItems;
            }
        })
    }

    /** @type {import("../components/practice-history-item/delete-button").DeleteButtonOnDeleteCallback} */
    function removeFromList(historyItemId) {
        setItems((oldItems) => oldItems.filter((item) => item.id !== historyItemId));
    }

    function HistoryItem({item, setItemStatus, removeFromList}) {
        return (
            <div className="columns pt-3 pb-3">
                <div className="column pt-0 mt-0 pb-1 mb-0">
                    <HistoryItemContent item={item} />
                </div>
                <div className="column is-narrow pt-0 pb-0 mt-0 mb-0">
                    <div className="level is-mobile">
                        <div className="level-left"></div>
                        <div className="level-right">
                            <div className="level-item">
                                <div className="buttons has-addons">
                                    <ToHubButton historyItemId={item.id} onMove={() => navigate(paths.practice.hubV2)} />
                                    <ChangeButton historyItemId={item.id} historyItemStatus={item.status} onChange={setItemStatus}/>
                                    <DeleteButton historyItemId={item.id} onDelete={removeFromList} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    /**
     * 
     * @param {{items: Array<import("../api").FemHistoryItem>}} param0 
     */
    function PracticeHistoryItemsTable({items}) {
        return (
            <table className="table is-striped is-fullwidth">
                <thead>
                    <th></th>
                </thead>
                <tbody>
                    {items.length === 0 ? (
                        <tr>
                            <td colSpan={2}>
                                Looks like you haven't start practicing, yet. If you feel like 
                                it, <Link to={paths.practice.hubV2}>head to the hub to start practicing</Link>.
                            </td>
                        </tr>
                    ) : items.map((item) => (
                        <tr>
                            <td>
                                <HistoryItem item={item} removeFromList={removeFromList} setItemStatus={setItemStatus}/>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    return (
        <>
            <h1 className="title">Practice History</h1>
            <PracticeHistoryItemsTable items={items} />
        </>
    )
}