// @ts-check

import React, { useState } from "react";

/**
 * @callback BooleanStateChangeHandlerFunctionStatusCallback
 * @param {boolean} isSuccess True if change is succesful, false otherwise.
 */
/**
 * @callback BooleanStateChangeHandlerFunction
 * @param {boolean} newBooleanState
 * @param {BooleanStateChangeHandlerFunctionStatusCallback} cb
 */
/**
 * @callback SetBooleanStateWithComputeFunction
 * @param {boolean} oldValue
 */
/**
 * @callback SetBooleanStateFunction
 * @param {boolean | SetBooleanStateWithComputeFunction} valueOrComputeFunction
 */

/**
 * 
 * @param {{state: boolean | {state: boolean, setState: SetBooleanStateFunction, isAvailable: boolean, setIsAvailable: SetBooleanStateFunction}, label: string | {trueLabel: string, falseLabel: string}, handleBooleanStateChange: BooleanStateChangeHandlerFunction, additionalStyleClass?: {forTrueState?: string, forFalseState?: string}, shortLabelForTouch?: string, shortLabelForMobile?: string}} param0 
 * @returns {any} Returns jsx.
 */
export default function BooleanStateButton({ state, label, handleBooleanStateChange, additionalStyleClass = {forTrueState: 'is-success'}, shortLabelForTouch, shortLabelForMobile}) {
    const [internalButtonState, setInternalButtonState] = useState(typeof state === 'boolean' ? state : false); // false will never be used
    const [internalIsAvailableState, setInternalIsAvailableState] = useState(true);

    const [buttonState, setButtonState] =
        (typeof state === 'boolean' ? [internalButtonState, setInternalButtonState] : [state.state, state.setState]);
    const [isAvailableState, setIsAvailableState] =
        (typeof state === 'boolean' ? [internalIsAvailableState, setInternalIsAvailableState] : [state.isAvailable, state.setIsAvailable]);
    const [trueLabel, falseLabel] = (typeof label === 'string' ? [label, label] : [label.trueLabel, label.falseLabel]);

    /**
     * 
     * @param {any} event 
     */
    async function handleClick(event) {
        event.stopPropagation();
        setIsAvailableState(false);
        const newButtonState = !buttonState;
        await handleBooleanStateChange(newButtonState, (isSuccessful) => {
            if (!isSuccessful) {
                setButtonState(!newButtonState)
            } else {
                setButtonState(newButtonState);
            }
            setIsAvailableState(true);
        });
    }

    function buttonClassName() {
        let className = "button";
        if (buttonState) {
            if (additionalStyleClass.forTrueState) {
                className += " " + additionalStyleClass.forTrueState;
            }
        } else {
            if (additionalStyleClass.forFalseState) {
                className += " " + additionalStyleClass.forFalseState;
            }
        }
        if (!isAvailableState) {
            className += " is-loading";
        }
        return className;
    }

    /**
     * 
     * @returns {React.JSX.Element}
     */
    function buttonLabel() {
        const labelForState = (buttonState ? trueLabel : falseLabel);
        if (shortLabelForTouch) {
            return (
                <>
                    <span className="is-hidden-desktop">{shortLabelForTouch}</span>
                    <span className="is-hidden-touch">
                        {labelForState}
                    </span>
                </>
            );
        } else if (shortLabelForMobile) {
            return (
                <>
                    <span className="is-hidden-tablet">{shortLabelForMobile}</span>
                    <span className="is-hidden-mobile">
                        {labelForState}
                    </span>
                </>
            );
        } else {
            return (<>{labelForState}</>);
        }
    }

    return (
        <>
            {/* <button className={"button"
                    + (buttonState ? " is-success" : "")
                    + (!isAvailableState ? " is-loading" : "")} */}
            <button className={buttonClassName()}
                    disabled={!isAvailableState}
                    onClick={async (event) => await handleClick(event)}>
                {buttonLabel()}
            </button> 
        </> 
    );
}