import { useState } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom"
import { bookDeleteRequest } from "../api";
import { useApi } from "react-base";

export default function Books() {
    const navigate = useNavigate();
    const fetchFromApi = useApi();
    const [error, setError] = useState("");

    async function deleteBook(bookId) {
        // delete book
        const response = await fetchFromApi(bookDeleteRequest(bookId));
        if (!response.isOk) {
            setError("Could not delete book.");
            return;
        }
        navigate("/books", { replace: true });
    }

    const books = (useLoaderData() || {}).books || [];

    return (
        <>
            {error && <p>{error}</p>}
            <table className="table is-striped is-fullwidth button-aligned-contents">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>ISBN</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {books.map((book) => (
                        <tr>
                            <td className="pt-4">
                                {book.name}
                            </td>
                            <td className="pt-4">
                                {book.isbn}
                            </td>
                            <td className="is-narrow">
                                <div className="buttons is-right">
                                    <Link className="button is-ghost" to={`/books/${book.id}`} >Chapters</Link>
                                </div>
                            </td>
                            <td className="has-text-right is-narrow">
                                <div className="buttons has-addons is-right">
                                    <button className="button" onClick={() => {deleteBook(book.id)}}>Delete</button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}