import { useState } from "react";
import { Link, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { chapterDeleteRequest } from "../api";
import { useApi } from "react-base";

export default function Book() {
    const navigate = useNavigate();
    const fetchFromApi = useApi();
    const [error, setError] = useState();
    const { id } = useParams();
    const bookWithChapters = (useLoaderData() || {}) || [];
    console.log(`Book chapters for book ${JSON.stringify(bookWithChapters)}`);

    async function addButtonOnClick() {
        navigate(`/books/${id}/chapters/new`, { replace: true });
    }

    async function deleteChapter(chapterId) {
        // delete book
        const response = await fetchFromApi(chapterDeleteRequest(bookWithChapters.id, chapterId));
        if (!response.isOk) {
            setError("Could not delete chapter.");
            return;
        }
        navigate(`/books/${bookWithChapters.id}`, { replace: true });

    }

    return (
        <>
            {error && <p>{error}</p>}
            <p>Here are the chapters for the book "{bookWithChapters.name}".</p>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th className="pt-4">Title</th>
                        <th colSpan={2}>
                            <div className="buttons has-addons is-right">
                                <button className="button is-fullwidth" onClick={addButtonOnClick}>Add new chapter</button>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {bookWithChapters.chapters.map((chapter) => (
                        <tr>
                            <td className="pt-4">
                                {chapter.title}
                            </td>
                            <td className="is-narrow">
                                <div className="buttons is-right">
                                    <Link className="button is-ghost" to={`/books/${bookWithChapters.id}/chapters/${chapter.id}`} >Sections</Link>
                                </div>
                            </td>
                            <td className="has-text-right is-narrow">
                                <div className="buttons has-addons is-right">
                                    <button className="button" onClick={() => {deleteChapter(chapter.id)}}>Delete</button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>

    );
}