// @ts-check

import { json } from "react-router-dom";

import paths from './paths.js';

import {
  chapterGetRequest,
  sectionGetRequest,
  bookGetRequest,
  booksGetAllRequest,
  practiceRecommendationsGetAllRequest,
  leetcodeQuestionSetsGetRequest,
  leetcodeConfigGetRequest, 
  practiceRecommendationsV2GetAllRequest,
  hackerrankConfigGetRequest,
  api,
  practiceHistoryItemsGetAllRequest, } from './api';

import ErrorPage from './error-page';
import MainPage from './routes/main-page';
import Knows from './routes/knows';
import BooksNew from './routes/books-new';
import Books from './routes/books';
import Book from './routes/book';
import BookChaptersNew from './routes/book-chapters-new';
import BookChapter from './routes/book-chapter';
import BookChapterSectionsNew from './routes/book-chapter-sections-new';
import BookChapterSectionsEdit from './routes/book-chapter-sections-edit';
import PracticeHub from "./routes/practice-hub.js";
import Sources from "./routes/sources.js";
import LeetcodeQuestionSets from "./routes/leetcode-question-sets.js";
import SourceLeetcode from "./routes/source-leetcode.js";
import PracticeHubV2 from "./routes/practice-hub-v2.js";

// mocks. none should make it to production
import SourceHackerrank from "./routes/source-hackerrank.js";
import About from "./routes/about.js";
import PracticeHistory from "./routes/practice-history.js";
import React from "react";

/**
 * 
 * @param {Request} request 
 * @returns {Promise<Response>}
 */
async function apiResponse(request) {
  const response = await api(request);
  return json(response.data || '', {status: response.status});
}

export const unauthenticatedPaths = [
  {
    path: "/",
    element: <MainPage />,
    errorElement: <ErrorPage />,
  },
];

export const authenticatedPaths = [
  {
    path: paths.book.create,
    element: <BooksNew />,
    errorElement: <ErrorPage />,
  },
  {
    path: paths.book.chapter.create,
    element: <BookChaptersNew />,
    errorElement: <ErrorPage />,
  },
  {
    path: paths.book.chapter.get,
    element: <BookChapter />,
    loader: async ({ params }) => {
      // console.log(`Loading book sections for chapter with id ${params.chapterId} in book with id ${params.bookId}`);
      const requestParams = {
        includeSections: true,
        includeSectionPracticeUnits: true,
        includeBook: true,
        includeSectionKnows: true,
      }
      return apiResponse(chapterGetRequest(params.bookId, params.chapterId, requestParams));
    },
    errorElement: <ErrorPage />,
  },
  {
    path: paths.book.chapter.section.create,
    element: <BookChapterSectionsNew />,
    errorElement: <ErrorPage />,
  },
  {
    path: paths.book.chapter.section.edit,
    element: <BookChapterSectionsEdit />,
    loader: async ({ params }) => {
      // console.log(`Loading section with id ${params.sectionId} for chapter with id ${params.chapterId} in book with id ${params.bookId}`);
      return apiResponse(sectionGetRequest(params.bookId, params.chapterId, params.sectionId,
        { includePracticeUnits: true, includeKnows: true }));
    },
    errorElement: <ErrorPage />,
  },
  {
    path: paths.book.get,
    element: <Book />,
    loader: async ({ params }) => {
      // console.log(`Loading book chapters for book with id ${params.id}`);
      return apiResponse(bookGetRequest(params.id, {includeChapters: true}));
    },
    errorElement: <ErrorPage />,
  },
  {
    path: paths.book.getAll,
    element: <Books />,
    loader: async () => {
      // console.log("Loading books.");
      return apiResponse(booksGetAllRequest());
    },
    errorElement: <ErrorPage />,
  },
  {
    path: paths.knows,
    element: <Knows />,
    loader: async () => {
      // console.log("Loading user knowledge.");
      return apiResponse(booksGetAllRequest({ includeOnlyKnows: true }));
    },
    errorElement: <ErrorPage />,
  },
  {
    path: paths.practice.hub,
    element: <PracticeHub />,
    loader: async () => {
      // console.log("Loading practice recommendations.");
      return apiResponse(practiceRecommendationsGetAllRequest());
    },
    errorElement: <ErrorPage />,
  },
  {
    path: paths.practice.hubV2,
    element: <PracticeHubV2 />,
    loader: async () => {
      // console.log("Loading practice recommendations. V2 style..");
      return apiResponse(practiceRecommendationsV2GetAllRequest());
    },
    errorElement: <ErrorPage />,
  },
  {
    path: paths.sources,
    element: <Sources />,
    errorElement: <ErrorPage />,
  },
  {
    path: paths.leetcode,
    element: <SourceLeetcode />,
    loader: async () => {
      // console.log("Loading leetcode question sets knowing status for the user.");
      return apiResponse(leetcodeConfigGetRequest());
    },
    errorElement: <ErrorPage />,
  },
  {
    path: paths.hackerrank,
    element: <SourceHackerrank />,
    loader: async () => {
      // console.log("Loading hackerrank question sets knowing status for the user.");
      return apiResponse(hackerrankConfigGetRequest());
    },
    errorElement: <ErrorPage />,
  },
  {
    path: paths.leetcodeQuestionSets,
    element: <LeetcodeQuestionSets />,
    loader: async () => {
      // console.log("Loading leetcode question sets knowing status for the user.");
      return apiResponse(leetcodeQuestionSetsGetRequest());
    },
    errorElement: <ErrorPage />,
  },
  {
    path: paths.about,
    element: <About />,
    errorElement: <ErrorPage />,
  },
  {
    path: paths.practice.history.getAll,
    element: <PracticeHistory />,
    loader: async () => {
      return apiResponse(practiceHistoryItemsGetAllRequest());
      // return mock data for development
      /** @type {Array<import("./api").FemHistoryItem>} */
      const mockData = [
        {
          id: 1,
          question: {
            name: "A very difficult question",
            url: "gopher://somewhere",
            difficulty: "oh so very easy",
            source: {name: "fleetcode"},
            questionSets: [{name: "tomatoes set"}],
            tags: [{name: "this tag"}, {name: "that tag"}],
          },
          status: "SOLVED",
          time: Date.now() - (2 * 24 * 60 * 60 * 1000),
        },
        {
          id: 2,
          question: {
            name: "Superstupidifyingly zambola",
            url: "gopher://somewhere",
            difficulty: "oh so very easy",
            source: {name: "fleetcode"},
            questionSets: [{name: "patlican set"}],
            tags: [{name: "this tag"}, {name: "that tag"}],
          },
          status: "FAILED",
          time: Date.now() - (3 * 24 * 60 * 60 * 1000),
        },
        {
          id: 3,
          question: {
            name: "A very easy question",
            url: "gopher://somewhere",
            difficulty: "nah not-so easy",
            source: {name: "acreland"},
            questionSets: [{name: "barbeque set"}],
            tags: [{name: "this tag"}, {name: "that tag"}],
          },
          status: "SOLVED",
          time: Date.now() - (4 * 24 * 60 * 60 * 1000),
        },
      ];
      return json(mockData);
    }
  }
];
