import { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { sectionCreateRequest } from "../api";
import { useApi } from 'react-base';

export default function BookChapterSectionsNew() {
    const [section, setSection] = useState({});
    const [errors, setErrors] = useState({});
    const { bookId, chapterId } = useParams();
    const navigate = useNavigate();
    const fetchFromApi = useApi();

    console.log(`bookId: ${bookId} chapterId: ${chapterId}`);

    async function handleSubmit(event) {
        event.preventDefault(); // this prevents browser to send a request to the server

        // validate
        const newErrors = {};
        if (!section.title) {
           newErrors.title = "Section title is required.";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        // send to server
        const sectionToSend = {
            title: section.title,
            question_range_start: "1",
            question_range_end: section.numberOfQuestions,
        }
        const response = await fetchFromApi(sectionCreateRequest(bookId, chapterId, sectionToSend));

        if (!response.isOk) {
            newErrors.result = "Error saving the section: " + JSON.stringify(response);
            setErrors(newErrors);
            return;
        }

        // return to chapter page and refresh data if successful, display error and stay if not
        navigate(`/books/${bookId}/chapters/${chapterId}`, { replace: true })
    }

    return (
        <div>
            {errors.result && <p>{errors.result}</p>}
            <br />
            <h1>Add new section</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="title">Title (*):</label>
                <input
                    type="text"
                    id="title"
                    value={section.title}
                    onChange={(event) => setSection({...section, title: event.target.value})}
                />
                {errors.title && <p>{errors.title || ""}</p>}
                <br />

                <label htmlFor="num_questions">Number of questions:</label>
                <input
                    type="text"
                    id="num_questions"
                    value={section.numberOfQuestions || ""}
                    onChange={(event) => setSection({...section, numberOfQuestions: event.target.value})}
                />
                {errors.title && <p>{errors.title}</p>}
                <br />

                <button type="submit">Add</button>
            </form>
            <p>(*) Required.</p>
        </div>
    )
}