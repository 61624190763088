// @ts-check

import { useEffect, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import { practiceRecommendationV2MarkDismissedRequest, practiceRecommendationV2MarkFailedRequest, practiceRecommendationV2MarkSolvedRequest, practiceRecommendationsV2GetAllRequest, practiceRecommendationsV2ResetAndGetRequest } from "../api";
import { ActionButton, useApi } from "react-base";
import paths from "../paths";
import React from "react";
import QuestionDescription from "../components/question-description";

export default function PracticeHubV2() {
    const fetchFromApi = useApi();

    const practiceRecommendations = /** @type {Array<import("../api").FemRecommendation>} */ (useLoaderData());
    const [errors, setErrors] = useState(/** @type {{result?: string}} */ ({}));
    const [recommendations, setRecommendations] = useState(practiceRecommendations);
    const [refreshTrigger, setRefreshTrigger] = useState(0);

    useEffect(() => {
        if (refreshTrigger > 0) {
            let ignoreResponse = false;
            async function run() {
                // WARN: this requires read-after write support for at least the number of recommendations returned in a get response
                const response = await fetchFromApi(practiceRecommendationsV2GetAllRequest());
                if (response.isOk && !ignoreResponse) {
                    setRecommendations(response.data);
                }
            }
            run();
            return () => {
                ignoreResponse = true;
            };
        }

        return;
    }, [refreshTrigger]);

    async function recalculateAndReload(event) {
        event.stopPropagation();

        fetchFromApi(practiceRecommendationsV2ResetAndGetRequest())
        .then((response) => {
            const newErrors = {};
            if (!response.isOk) {
                newErrors.result = "Error refreshing recommendations."
                setErrors(newErrors);
                return;
            }
            return response.data;
        })
        .then((newRecommendations) => setRecommendations(newRecommendations));
    }

    function removeRecommendation(id) {
        const newErrors = {};
        const index = recommendations.findIndex((rec) => rec.recommendationId === id);
        if (index === -1) {
            // Marked a recommendation as solved, but it's not in the list. Something fishy is going on.
            newErrors.result = "Unexpected error."
            setErrors(newErrors);
            return;
        } else {
            const newRecommendations = recommendations.splice(1, index).concat(recommendations.splice(index+1));
            setRecommendations(newRecommendations);
            setRefreshTrigger((val) => val+1);
        }
    }

    async function markSolved(event, practiceRecommendationId) {
        event.stopPropagation();
        const response = await fetchFromApi(practiceRecommendationV2MarkSolvedRequest(practiceRecommendationId));

        const newErrors = {}
        if (!response.isOk) {
            newErrors.result = "Error marking the item as solved: " + JSON.stringify(response);
            setErrors(newErrors);
            return;
        }

        removeRecommendation(practiceRecommendationId);
        setRefreshTrigger((val) => val+1);
    }

    async function markFailed(event, practiceRecommendationId) {
        event.stopPropagation();
        const response = await fetchFromApi(practiceRecommendationV2MarkFailedRequest(practiceRecommendationId));

        const newErrors = {}
        if (!response.isOk) {
            newErrors.result = "Error marking the item as not solved: " + JSON.stringify(response);
            setErrors(newErrors);
            return;
        }

        removeRecommendation(practiceRecommendationId);
    }

    /**
     * 
     * @param {number} recommendationId 
     * @param {import("react-base/dist/components/action-button").ActionButtonClickHandlerCallback} callback 
     */
    async function markDismissed(recommendationId, callback) {
        const response = await fetchFromApi(practiceRecommendationV2MarkDismissedRequest(recommendationId));
        if (!response.isOk) {
            const newErrors = {};
            newErrors.result = "Error marking the item as will not be solved: " + JSON.stringify(response);
            setErrors(newErrors);
        } else {
            removeRecommendation(recommendationId);
        }
        callback();
    }

    /**
     * 
     * @param {import("../api").FemRecommendation} pr 
     * @returns 
     */
    function formatTableRow(pr) {
        return (
            <tr>
                <td>
                    <div className="columns pt-3 pb-3">
                        <div className="column pt-0 mt-0 pb-1 mb-0">
                            <QuestionDescription question={pr.question} />
                        </div>
                        <div className="column is-narrow pt-0 pb-0 mt-0 mb-0">
                            <div className="level is-mobile">
                                <div className="level-left"></div>
                                <div className="level-right">
                                    <div className="level-item">
                                        <div className="buttons has-addons">
                                            <button className="button is-responsive" onClick={(event) => {markSolved(event, pr.recommendationId)}}>Solved</button>
                                            <button className="button is-responsive" onClick={(event) => {markFailed(event, pr.recommendationId)}}>Couldn't Solve</button>
                                            <ActionButton additionalButtonStyles="is-responsive" onClick={(callback) => markDismissed(pr.recommendationId, callback)}>Won't Solve</ActionButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        );
    }

    return (
        <>
            {errors.result && <><p>{errors.result}</p><br/></>}
            <p>
                <small>Here is a list of practice recommendations curated for you from the sources you selected.<br />
                As you practice and mark them as solved or not solved, this list will update to help you continue practicing with the best next question.
                <br/>
                <strong>Perfect practice makes perfect. Have fun!</strong>
                </small>
            </p>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <th className="is-narrow">
                        <div className="level is-mobile">
                            <div className="level-left"></div>
                            <div className="level-right">
                                <div className="level-item">
                                    <button className="button is-responsive"
                                            onClick={(event) => {recalculateAndReload(event)}}>
                                        Refresh Recommendations
                                    </button>
                                </div>
                            </div>
                        </div>
                    </th>
                </thead>
                <tbody>
                    {recommendations.length === 0 ? (
                        <tr>
                            <td colSpan={2}>
                                No recommendations. Did you enable any sources, e.g. <Link to={paths.leetcode}>Leetcode</Link>, <Link to={paths.hackerrank}>HackerRank</Link>, or others?
                            </td>
                        </tr>
                    ) : recommendations.map((pr) => formatTableRow(pr)) }
                    {}
                </tbody>
            </table>
        </>
    );
}