// @ts-check

/** @typedef {import("../../../api/front-end-types.js").FemSource} FemSource */

import { useLoaderData } from "react-router-dom";
import {
    hackerrankConfigExcludeRequest,
    hackerrankConfigIncludeRequest,
    hackerrankConfigSetDifficultyUpdateRequest,
    hackerrankConfigTagDifficultyUpdateRequest } from "../api";
import SourceConfigPage from "../components/source-config";
import React from "react";

export default function SourceHackerrank() {
    /** @type {FemSource} */
    // @ts-ignore
    const source = useLoaderData();

    return (
        <SourceConfigPage
            sourceName={source.name}
            dataProp={source.isSourceIncluded ? source.config : undefined}
            questionSetDifficultyUpdateRequestFunction={hackerrankConfigSetDifficultyUpdateRequest}
            tagDifficultyUpdateRequestFunction={hackerrankConfigTagDifficultyUpdateRequest}
            includeRequestFunction={hackerrankConfigIncludeRequest}
            excludeRequestFunction={hackerrankConfigExcludeRequest}
        />
    );
}
