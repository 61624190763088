import { useState } from "react";
import { leetcodeQuestionSetsUpdateRequest } from "../api";
import { useLoaderData } from "react-router-dom";
import { useApi } from "react-base";

export default function LeetcodeQuestionSets() {
    const fetchFromApi = useApi();

    const leetcodeQuestionSets = useLoaderData()
    const initialQuestionSetStates = {};
    for (const questionSet of leetcodeQuestionSets) {
        initialQuestionSetStates[questionSet.id] = questionSet.knows;
    }
    const [questionSetStates, setQuestionSetStates] = useState(initialQuestionSetStates);
    const [errors, setErrors] = useState({});
    const [userMessage, setUserMessage] = useState("");


    async function handleSubmit(event) {
        event.preventDefault(); // this prevents browser to send a request to the server

        const submission = Object.keys(questionSetStates).flatMap((id) =>
            (questionSetStates[id] ? { id: id, knows: questionSetStates[id], } : []))
        const response = await fetchFromApi(leetcodeQuestionSetsUpdateRequest(submission));

        const newErrors = {};
        if (!response.isOk) {
            newErrors.result = "Error saving the leetcode question sets knowledge status: " + JSON.stringify(response);
            setErrors(newErrors);
            return;
        }

        setUserMessage("Saved leetcode question sets configuration.");
    }

    return (
        <div>
            {errors.result && <p>{errors.result}</p>}
            {(userMessage.length !== 0) && <p>{userMessage}</p>}
            <form onSubmit={handleSubmit}>
                <p>You can select the leetcode question sets to include in your practice recommendations:</p>
                <ul style={{'list-style': 'none'}}>
                    {leetcodeQuestionSets.map((set) => (
                        <li>
                            <label className="checkbox" htmlFor={`leetcodeQuestionSet_${set.id}`}>
                                <input
                                    type="checkbox"
                                    id={`leetcodeQuestionSet_${set.id}`}
                                    name={set.name}
                                    checked={questionSetStates[set.id] || false}
                                    onChange={(event) => { setQuestionSetStates({...questionSetStates, [set.id]: event.target.checked}); }}
                                />
                                &nbsp;{set.name}
                            </label>
                        </li>
                    ))}
                </ul>
                <button className="button" type="submit" >Update</button>
            </form>
        </div>
    );
}