/**
 * @module react-base
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { authenticatedPaths, unauthenticatedPaths } from './router';
import ErrorPage from './error-page';
import appConfig from './app-config';
import paths from './paths';
import { App } from 'react-base';

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App 
      unauthenticatedPaths = {unauthenticatedPaths}
      authenticatedPaths = {authenticatedPaths}
      ErrorPage = {ErrorPage}
      appVisibleName = {appConfig.appVisibleName}
      menu = {appConfig.menu}
      appHomePath = {paths.practice.hubV2}
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
