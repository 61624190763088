// @ts-check

/** @typedef {import("../../../api/front-end-types.js").FemSourceDifficulty} FemSourceDifficulty */
/** @typedef {import("../../../api/front-end-types.js").FemQuestionSet} FemQuestionSet */
/** @typedef {import("../../../api/front-end-types.js").FemTag} FemTag */
/** @typedef {import("../../../api/front-end-types.js").FemSourceConfig} FemSourceConfig */
/** @typedef {import("./difficulty-selector.js").DifficultySettingChangeHandlerStatusCallback} DifficultySettingChangeHandlerStatusCallback */
/** @typedef {import("./difficulty-selector.js").DifficultySettingChangeHandler} DifficultySettingChangeHandler */
/** @typedef {import("../api.js").QuestionSetDifficultyUpdateRequestFunction} QuestionSetDifficultyUpdateRequestFunction */
/** @typedef {import("../api.js").TagDifficultyUpdateRequestFunction} TagDifficultyUpdateRequestFunction */
/** @typedef {import("../api.js").IncludeRequestFunction} IncludeRequestFunction */
/** @typedef {import("../api.js").ExcludeRequestFunction} ExcludeRequestFunction */
/**
 * @typedef {object} SourceConfigPageProps
 * @prop {string} sourceName
 * @prop {number} sourceId
 * @prop {FemSourceConfig | undefined} dataProp
 * @prop {QuestionSetDifficultyUpdateRequestFunction} questionSetDifficultyUpdateRequestFunction
 * @prop {TagDifficultyUpdateRequestFunction} tagDifficultyUpdateRequestFunction
 * @prop {IncludeRequestFunction} includeRequestFunction
 * @prop {ExcludeRequestFunction} excludeRequestFunction 
 */
/**
 * @callback QuestionSetDifficultyChangeHandler
 * @param {number} sourceId
 * @param {number} setId 
 * @param {number} difficultyId 
 * @param {boolean} newIncludeState 
 * @param {DifficultySettingChangeHandlerStatusCallback} cb Callback function to report success or failure.
 */
/**
 * @callback TagDifficultyChangeHandler
 * @param {number} sourceId
 * @param {number} tagId 
 * @param {number} difficultyId 
 * @param {boolean} newIncludeState 
 * @param {DifficultySettingChangeHandlerStatusCallback} cb Callback function to report success or failure.
 * @returns {Promise<void>}
 */
/**
 * @callback IsSourceIncludedNewValueComputeFunction
 * @param {boolean} oldState
 * @returns {boolean}
 */
/**
 * @callback IsSourceIncludedSetFunction
 * @param {boolean | IsSourceIncludedNewValueComputeFunction} newValueOrNewValueComputeFunction
 */
/**
 * @callback DataNewValueComputeFunction
 * @param {FemSourceConfig | undefined} oldState
 * @returns {FemSourceConfig | undefined}
 */
/**
 * @callback DataSetFunction
 * @param {FemSourceConfig | undefined | DataNewValueComputeFunction} newValueOrNewValueomputeFunction
 */


import { useApi } from 'react-base';
import BooleanStateButton from "./boolean-state-button.js";
import DifficultySelector from "./difficulty-selector.js";
import React, { useState } from "react";

/**
 * 
 * @param {SourceConfigPageProps} param0 
 * @returns {React.JSX.Element}
 */
export default function SourceConfigPage({
        sourceName,
        sourceId,
        dataProp,
        questionSetDifficultyUpdateRequestFunction,
        tagDifficultyUpdateRequestFunction,
        includeRequestFunction,
        excludeRequestFunction}) {
    /** @type {[FemSourceConfig | undefined, DataSetFunction]} */
    const [data, setData] = useState(dataProp);
    const fetchFromApi = useApi();

    /** @type {QuestionSetDifficultyChangeHandler} */
    async function handleQuestionSetDifficultyChange(sourceId, setId, difficultyId, newIncludeState, cb) {
        const response = await fetchFromApi(
            questionSetDifficultyUpdateRequestFunction(
                sourceId, setId, difficultyId, newIncludeState));

        if (!response.isOk) {
            cb(false);
        }

            cb(true);
    }

    /** @type {TagDifficultyChangeHandler} */
    async function handleTagDifficultyChange(sourceId, tagId, difficultyId, newIncludeState, cb) {
        const response = await fetchFromApi(
            tagDifficultyUpdateRequestFunction(
                sourceId, tagId, difficultyId, newIncludeState));

        if (!response.isOk) {
            cb(false);
        }

        cb(true);
    }

    /**
     * 
     * @param {FemTag | FemQuestionSet} param0 
     * @param {Array<FemSourceDifficulty>} difficulties
     * @param {QuestionSetDifficultyChangeHandler | TagDifficultyChangeHandler} handleDifficultyChange 
     * @param {number} sourceId
     * @returns {any} Returns jax
     */
    function formatTableRow({id, name, include}, difficulties, sourceId, handleDifficultyChange) {
        return (
            <tr>
                <td>
                    <div className="columns">
                        <div className="column pt-4">{name}</div>
                        <div className="column is-narrow">
                            <DifficultySelector
                                difficulties={difficulties}
                                initialStates={include}
                                handleDifficultySettingChange={
                                    /** @type {DifficultySettingChangeHandler} */
                                    (difficultyId, newIncludeState, cb) =>
                                        handleDifficultyChange(sourceId, id, difficultyId, newIncludeState, cb)}
                            />
                        </div>
                    </div>
                </td>
            </tr>
        );
    }

    /**
     * 
     * @param {FemQuestionSet} set 
     * @param {Array<FemSourceDifficulty>} difficulties
     * @param {number} sourceId
     * @returns {React.JSX.Element}
     */
    function formatQuestionSetTableRow(set, difficulties, sourceId) {
        return formatTableRow(set, difficulties, sourceId, handleQuestionSetDifficultyChange);
    }

    /**
     * 
     * @param {FemTag} tag 
     * @param {Array<FemSourceDifficulty>} difficulties
     * @param {number} sourceId
     * @returns {React.JSX.Element}
     */
    function formatTagTableRow(tag, difficulties, sourceId) {
        return formatTableRow(tag, difficulties, sourceId, handleTagDifficultyChange);
    }

    /**
     * 
     * @param {string} title 
     * @param {Array<FemQuestionSet>} questionSets 
     * @param {Array<FemSourceDifficulty>} difficulties
     * @param {number} sourceId
     * @return {React.JSX.Element}
     */
    function formatQuestionSets(title, questionSets, difficulties, sourceId) {
        if (questionSets.length > 0) {
            return (
                <>
                    <h1>{title}</h1>
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            <th></th>
                        </thead>
                        <tbody>
                            {questionSets.map((set) => formatQuestionSetTableRow(set, difficulties, sourceId))}
                        </tbody>
                    </table>
                </>
            );

        } else {
            return (<></>);
        }
    }

    /**
     * 
     * @param {string} title 
     * @param {Array<FemTag>} tags 
     * @param {Array<FemSourceDifficulty>} difficulties
     * @param {number} sourceId
     */
    function formatTags(title, tags, difficulties, sourceId) {
        if (tags.length > 0) {
            return (
                <>
                    <h1>{title}</h1>
                    <table className="table is-striped is-fullwidth">
                        <thead>
                            <th></th>
                        </thead>
                        <tbody>
                            {tags.map((tag) => formatTagTableRow(tag, difficulties, sourceId))}
                        </tbody>
                    </table>
                </>
            );
        } else {
            return (<></>);
        }
    }

    async function handleSourceInclusionStateChange(isIncluded, cb) {
        const request = (isIncluded ? includeRequestFunction() : excludeRequestFunction());
        const response = await fetchFromApi(request);

        if (!response.isOk) {
            cb(false);
        } else {
            if (isIncluded) {
                setData(response.data);
            } else {
                setData(undefined);
            }
            cb(true);
        }
    }

    return (
        <>
            <div className="field is-grouped is-fullwidth">
                <BooleanStateButton
                    label={{
                        trueLabel: `Exclude all ${sourceName} questions`,
                        falseLabel: `Include questions from ${sourceName}`
                    }}
                    state={data ? true : false}
                    handleBooleanStateChange={async (newBooleanState, cb) => handleSourceInclusionStateChange(newBooleanState, cb)}
                    additionalStyleClass={{
                        forTrueState: "is-small is-fullwidth is-danger is-outlined",
                        forFalseState: "is-large is-fullwidth is-success is-outlined",
                    }}
                />
            </div>
            {data ? (
                <>
                    {formatQuestionSets(data.questionSetGroupTitle, data.questionSets, data.difficulties, sourceId)}
                    {formatTags(data.tagsGroupTitle, data.tags, data.difficulties, sourceId)}
                </>
            ): ""}
        </>
    );
}