import { useState } from "react";
import { Link, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { sectionDeleteRequest } from "../api";
import { useApi } from "react-base";

export default function BookChapter() {
    const navigate = useNavigate();
    const fetchFromApi = useApi();
    const [error, setError] = useState();
    const { bookId, chapterId } = useParams();
    const chapter = (useLoaderData() || {}) || []; // this is assumed here to include book and sections

    async function addButtonOnClick() {
        navigate(`/books/${bookId}/chapters/${chapterId}/sections/new`, { replace: true });
    }

    // delete section
    async function deleteSection(sectionId) {
        const response = await fetchFromApi(sectionDeleteRequest(chapter.book.id, chapter.id, sectionId));
        if (!response.isOk) {
            setError("Could not delete chapter.");
            return;
        }
        navigate(`/books/${chapter.book.id}/chapters/${chapter.id}`, { replace: true });
    }

    return (
        <>
            {error && <p>{error}</p>}
            <p>Here are the sections for</p>
            <ul style={{'list-style': 'none'}}>
                <li><span className="has-text-weight-bold">Book:</span>&nbsp;{chapter.book.name}</li>
                <li><span className="has-text-weight-bold">Chapter:</span>&nbsp;{chapter.title}</li>
            </ul>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th className="pt-4">Section title</th>
                        <th className="pt-4 is-narrow">Questions</th>
                        <th className="pt-4 is-narrow">You know?</th>
                        <th colSpan="2">
                            <div className="buttons has-addons is-right">
                                <button className="button is-fullwidth" onClick={addButtonOnClick}>Add new section</button>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {chapter.sections.map((section) => (
                        <tr>
                            <td className="pt-4">
                                {section.title}
                            </td>
                            <td className="pt-4">
                                {section.practice_units.length > 0
                                    ? String(section.practice_units[0].question_range_end
                                        - section.practice_units[0].question_range_start + 1)
                                    : ""
                                }
                            </td>
                            <td className="pt-4">
                                {section.knows ? "Yes" : ""}
                            </td>
                            <td className="is-narrow">
                                <div className="buttons is-right">
                                    <Link className="button is-ghost" to={`/books/${bookId}/chapters/${chapterId}/sections/${section.id}/edit`}>Edit</Link>
                                </div>
                            </td>
                            <td className="has-text-right is-narrow">
                                <div className="buttons has-addons is-right">
                                    <button className="button" onClick={() => {deleteSection(section.id)}}>Delete</button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>

    );
}