import { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { chapterCreateRequest } from "../api";
import { useApi } from 'react-base';

export default function BookChaptersNew() {
    const [title, setTitle] = useState("");
    const [errors, setErrors] = useState({});
    const { bookId } = useParams();
    const navigate = useNavigate();
    const fetchFromApi = useApi();

    async function handleSubmit(event) {
        event.preventDefault(); // this prevents browser to send a request to the server

        // validate
        const newErrors = {};
        if (title === "") {
           newErrors.title = "Chapter title is required.";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        // send to server
        const response = await fetchFromApi(chapterCreateRequest(bookId, title));

        if (!response.isOk) {
            newErrors.result = "Error saving the chapter: " + JSON.stringify(response);
            setErrors(newErrors);
            return;
        }

        // return to book page and refresh data if successful, display error and stay if not
        navigate(`/books/${bookId}`, { replace: true })
    }

    return (
        <div>
            {errors.result && <p>{errors.result}</p>}
            <br />
            <h1>Add new chapter</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="title">Title (*):</label>
                <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                />
                {errors.title && <p>{errors.title}</p>}
                <br />
                <button type="submit" >Add</button>
            </form>
            <p>(*) Required.</p>
        </div>
    )
}