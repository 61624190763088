import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { booksCreateRequest } from "../api";
import { useApi } from 'react-base';

export default function BooksNew() {
    const [bookName, setBookName] = useState("");
    const [isbn, setIsbn] = useState("");
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const fetchFromApi = useApi();

    async function handleSubmit(event) {
        event.preventDefault(); // this prevents browser to send a request to the server

        // validate
        const newErrors = {};
        if (bookName === "") {
           newErrors.name = "Book name is required.";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        // send to server
        const response = await fetchFromApi(booksCreateRequest(bookName, isbn));

        if (!response.isOk) {
            newErrors.result = "Error saving the book: " + JSON.stringify(response.body);
            setErrors(newErrors);
            return;
        }

        // return to books page if successful, display error and stay if not
        navigate("/books", { replace: true })
    }

    return (
        <div>
            <h1>Add new book</h1>
            {errors.result && <p>{errors.result}</p>}
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">Name (*):</label>
                <input
                    type="text"
                    id="name"
                    value={bookName}
                    onChange={(event) => setBookName(event.target.value)}
                />
                {errors.name && <p>{errors.name}</p>}
                <br />
                <label htmlFor="isbn">ISBN:</label>
                <input
                    type="text"
                    id="isbn"
                    value={isbn}
                    onChange={(event) => setIsbn(event.target.value)}
                />
                {errors.isbn && <p>{errors.isbn}</p>}
                <br />
                <button type="submit" >Add</button>
            </form>
            <p>(*) Required.</p>
        </div>
    )
}