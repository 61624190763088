// @ts-check

/** @typedef {{[key: number]: boolean}} DifficultyStates */
/**
 * @callback DifficultySettingChangeHandlerStatusCallback
 * @param {boolean} isSuccess If change is succesful, false otherwise.
 */
/**
 * @callback DifficultySettingChangeHandler
 * @param {number} difficultyId
 * @param {boolean} newIncludeState
 * @param {DifficultySettingChangeHandlerStatusCallback} cb
 * @returns {Promise<void>}
 */
/**
 * @callback FunctionSetDifficultyStatesWithValue
 * @param {DifficultyStates} difficultyStates
 */
/**
 * @callback FunctionSetDifficultyStates
 * @param {DifficultyStates | FunctionSetDifficultyStatesWithValue} difficultyStates
 */


import { useState } from "react";
import { capitalize } from "../helpers/others";
import React from "react";
import BooleanStateButton from "./boolean-state-button";

/**
 * 
 * @param {{difficulties: Array<{id: number, name: string}>, initialStates: DifficultyStates, handleDifficultySettingChange: DifficultySettingChangeHandler}} param0 
 * @returns {any}
 */
export default function DifficultySelector({ difficulties, initialStates, handleDifficultySettingChange}) {
    /** @type {[DifficultyStates, FunctionSetDifficultyStates]} */
    const [difficultyStates, setDifficultyStates] = useState(initialStates);
    /** @type {[DifficultyStates, FunctionSetDifficultyStates]} */
    const [availabilities, setAvailabilities] =
        useState(Object.keys(initialStates).reduce((acc, id) => ({...acc, [id]: true}), {}));

    /**
     * 
     * @param {number} difficultyId 
     * @param {boolean} value 
     */
    function setDifficultyInState(difficultyId, value) {
        setDifficultyStates(currentState => ({
            ...currentState,
            [difficultyId]: value,
        }));
    }

    /**
     * 
     * @param {number} difficultyId 
     * @param {boolean} isAvailable 
     */
    function setAvailability(difficultyId, isAvailable) {
        // WARNING: this state chage must be made with a function, otherwise successive clicks
        // may revert state back (if component gets rendered between calling handleDifficultySettingChange
        // and that function calling the callback to revert the "waiting/disabled" state)
        setAvailabilities(currentValue => ({
            ...currentValue,
            [difficultyId]: isAvailable,
        }));
    }

    /**
     * 
     * @param {number} difficultyId 
     */
    async function changeDifficultySetting(difficultyId) {
        setAvailability(difficultyId, false);
        const newIncludeState = !difficultyStates[difficultyId];
        setDifficultyInState(difficultyId, newIncludeState);
        await handleDifficultySettingChange(difficultyId, newIncludeState, (isSuccessful) => {
            if (!isSuccessful) {
                setDifficultyInState(difficultyId, !newIncludeState);
            }
            setAvailability(difficultyId, true);
        });
    }

    /**
     * 
     * @param {boolean} state 
     * @returns {Promise<void>}
     */
    async function flipAllDifficultiesAtState(state) {
        const promises = difficulties
            .filter((difficulty) => difficultyStates[difficulty.id] === state)
            .map((difficulty) => changeDifficultySetting(difficulty.id));
        return new Promise((resolve, reject) =>
            Promise.all(promises).then(() => resolve()).catch(() => reject()));
    }

    async function handleIncludeExcludeStateChange(newIncludeState, cb) {
        try {
            await flipAllDifficultiesAtState(!newIncludeState);
            cb(true);
        } catch (err) {
            cb(false);
            throw err;
        }
    }

    function difficultyButton(difficulty, noLabel = false) {
        return (
            <BooleanStateButton
                state={{
                    state: difficultyStates[difficulty.id],
                    setState: (valueOrComputeFunction) => {
                        const newValue = (typeof valueOrComputeFunction === 'function'
                            ? valueOrComputeFunction(difficultyStates[difficulty.id])
                            : valueOrComputeFunction);
                        setDifficultyInState(difficulty.id, newValue);
                    },
                    isAvailable: availabilities[difficulty.id],
                    setIsAvailable: (valueOrComputeFunction) => {
                        const newValue = (typeof valueOrComputeFunction === 'function'
                            ? valueOrComputeFunction(difficultyStates[difficulty.id])
                            : valueOrComputeFunction);
                        setAvailability(difficulty.id, newValue);
                    }
                }}
                label={capitalize(difficulty.name)}
                shortLabelForMobile={noLabel ? ">" : undefined}
                handleBooleanStateChange={async (newBooleanState, cb) => {
                    await handleDifficultySettingChange(difficulty.id, newBooleanState, cb);
                }}
                additionalStyleClass={{forTrueState: "is-success is-responsive", forFalseState: "is-responsive"}}
            />
        );
    }

    return (
        <div className="field is-grouped">
            <p className="control mb-0">
                {/* {includeExcludeButton()} */}
                <BooleanStateButton
                    state={{
                        state: (difficulties.find((difficulty) => difficultyStates[difficulty.id]) ? true : false),
                        setState: (valueOrComputeFunction) => {},
                        isAvailable: (difficulties.find((difficulty) => !availabilities[difficulty.id]) ? false : true),
                        setIsAvailable: (valueOrComputeFunction) => {},
                    }}
                    label={{trueLabel: 'Exclude', falseLabel: 'Include'}}
                    handleBooleanStateChange={async (newBooleanState, cb) => await handleIncludeExcludeStateChange(newBooleanState, cb)}
                    additionalStyleClass={{forTrueState: "is-responsive is-outlined is-danger", forFalseState: "is-responsive is-outlined is-success"}}
                />
            </p>
            <p className="control mb-0">
                <div className="buttons has-addons">
                    {difficulties.length > 0 ? difficultyButton(difficulties[0]) : ''}
                    {difficulties.slice(1, -1).map((difficulty) => difficultyButton(difficulty, true))}
                    {difficulties.length > 1 ? difficultyButton(difficulties[difficulties.length-1]) : ''}
                </div>
            </p>
        </div>
    );
}