// @ts-check

import React from "react";
import { ActionButton, useApi } from "react-base";
import { practiceHistoryItemDeleteRequest } from "../../api";

/** @typedef {(historyItemId: number) => void} DeleteButtonOnDeleteCallback */

/**
 * @typedef {Object} DeleteHistoryItemButtonProps
 * @prop {number} historyItemId
 * @prop {DeleteButtonOnDeleteCallback} onDelete
 */

/**
 * 
 * @param {DeleteHistoryItemButtonProps} param0 
 * @returns {React.JSX.Element}
 */
export default function DeleteButton({historyItemId, onDelete}) {
    const fetchFromApi = useApi();
    
    /** @type {import("react-base").ActionButtonClickHandler} */
    async function deleteHandler(callback) {
        const response = await fetchFromApi(practiceHistoryItemDeleteRequest(historyItemId));
        if (response.isOk) {
            onDelete(historyItemId);
        }
        callback();
    }

    return (
        <ActionButton onClick={deleteHandler} additionalButtonStyles="is-responsive">Delete</ActionButton>
    )
}