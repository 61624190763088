// @ts-check

import paths from "./paths";

/** @type {import("react-base").Menu} */
const menu = [
    {
        label: 'Practice',
        items: [
            { label: 'Practice Hub', target: paths.practice.hubV2, },
            { label: 'History', target: paths.practice.history.getAll, },
            { label: 'Your Stats', isSoon: true, },
        ],
    },
    {
        label: 'Sources',
        items: [
            { label: 'Leetcode', target: paths.leetcode, },
            { label: 'HackerRank', target: paths.hackerrank, },
            { label: 'CodeWars', isSoon: true, },
            { label: 'CoderPad', isSoon: true, },
            { label: 'Exercism', isSoon: true, },
            { label: 'Kaggle', isSoon: true, },
            { label: 'Your Books', isSoon: true, },
        ],
    },
    {
        label: 'Account',
        items: [
            { label: 'Change Password', target: paths.base.changePassword, },
            { label: 'Profile', isSoon: true, },
        ],
    },
    { label: 'Feedback & Requests', target: paths.base.feedback, },
    { label: 'About', target: paths.about, },
];

export default menu;
