// @ts-check

import React from "react";
import { ActionButton, Modal, useApi, useErrors } from "react-base";
import { practiceHistoryItemMarkDismissedRequest, practiceHistoryItemMarkSolvedRequest, practiceHistoryItemMarkUnsolvedRequest } from "../../api";

export default function ChangeModal({isShowing, setIsShowing, historyItemId, currentStatus, onChange}) {
    const [setError, resetError, _resetAllErrors, renderErrors] = useErrors();
    const fetchFromApi = useApi();
    const statusToText = {
        "SOLVED": "Solved",
        "FAILED": "Couldn't solve",
        "DISMISSED": "Decided not to solve",
    };

    const ERR_API = "api";
    const ERR_API_SUCCESS = "success";

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function markSolved(callback) {
        resetError(ERR_API, ERR_API_SUCCESS);
        const response = await fetchFromApi(practiceHistoryItemMarkSolvedRequest(historyItemId));
        if (response.isOk) {
            onChange(historyItemId, "SOLVED");
            setIsShowing(false);
        } else {
            setError(ERR_API, ERR_API_SUCCESS, "Could not mark item as solved.")
        }
        callback();
    }

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function markNotSolved(callback) {
        resetError(ERR_API, ERR_API_SUCCESS);
        const response = await fetchFromApi(practiceHistoryItemMarkUnsolvedRequest(historyItemId));
        if (response.isOk) {
            onChange(historyItemId, "FAILED");
            setIsShowing(false);
        } else {
            setError(ERR_API, ERR_API_SUCCESS, "Could not mark item as not solved.")
        }
        callback();
    }

    /** @type {import("react-base").ActionButtonClickHandler} */
    async function markDismissed(callback) {
        resetError(ERR_API, ERR_API_SUCCESS);
        const response = await fetchFromApi(practiceHistoryItemMarkDismissedRequest(historyItemId));
        if (response.isOk) {
            onChange(historyItemId, "DISMISSED");
            setIsShowing(false);
        } else {
            setError(ERR_API, ERR_API_SUCCESS, "Could not mark item as won't be solved.")
        }
        callback();
    }

    return (
        <Modal isShowing={isShowing} setIsShowing={setIsShowing}>
            <p>You can update the status of your practice:</p>
            <div className="field is-grouped is-grouped-centered is-fullwidth">
                <div className="control">
                    <ActionButton disabled={currentStatus === "SOLVED"} onClick={markSolved}>{statusToText["SOLVED"]}</ActionButton>
                </div>
                <div className="control">
                    <ActionButton disabled={currentStatus === "FAILED"} onClick={markNotSolved}>{statusToText["FAILED"]}</ActionButton>
                </div>
                <div className="control">
                    <ActionButton disabled={currentStatus === "DISMISSED"} onClick={markDismissed}>{statusToText["DISMISSED"]}</ActionButton>
                </div>
            </div>
            {renderErrors()}
        </Modal>
    );
}