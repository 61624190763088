// @ts-check

/** @typedef {{status: number, isOk: boolean, data?: any}} ApiResponse */
/** @typedef {import("../../api/front-end-types").FemHistoryItem} FemHistoryItem */
/** @typedef {import("../../api/front-end-types").FemHistoryItemStatus} FemHistoryItemStatus */
/** @typedef {import("../../api/front-end-types").FemRecommendation} FemRecommendation */
/** @typedef {import("../../api/front-end-types").FemQuestion} FemQuestion */

import { setLoggedOut } from "react-base";

/** @typedef {"leetcode" | "hackerrank"} SourceName */
/**
 * @callback QuestionSetDifficultyUpdateRequestFunction
 * @param {number} sourceId 
 * @param {number} setId 
 * @param {number} difficultyId 
 * @param {boolean} include 
 * @returns {Request}
 */
/**
 * @callback TagDifficultyUpdateRequestFunction
 * @param {number} sourceId 
 * @param {number} setId 
 * @param {number} difficultyId 
 * @param {boolean} include 
 * @returns {Request}
 */
/**
 * @callback IncludeRequestFunction
 * @returns {Request}
 */
/**
 * @callback ExcludeRequestFunction
 * @returns {Request}
 */

// const baseUrl = "http://localhost:3000"
const baseUrl = window.location.origin;
/** @type {{get: 'GET', post: 'POST', put: 'PUT', delete: 'DELETE'}} */
const httpQueryType = {
    get: 'GET',
    post: 'POST',
    put: 'PUT',
    delete: 'DELETE'
}

/**
 * 
 * @param {{url: string, method?: 'GET' | 'POST' | 'PUT' | 'DELETE', body?: any}} param0 
 * @returns {Request}
 */
function request({ url, method = 'GET', body = undefined }) {
    if (!['GET', 'POST', 'PUT', 'DELETE']) {
        throw new RangeError('method parameter must be one of GET, POST, PUT, or DELETE');
    }
    const requestParams = {
        method: method,
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
    }
    if (body) {
        requestParams.body = JSON.stringify(body);
    } else {
        if (httpQueryType.post === method || httpQueryType.put === method) {
            requestParams.body = JSON.stringify({});
        }
    }
    return new Request(url, requestParams);
}

/**
 * 
 * @param {string} url 
 * @param {Object} [data]
 * @returns {Request}
 */
function postRequest(url, data = undefined) {
    return request({url: url, method: httpQueryType.post, body: data});
}

/**
 * 
 * @param {Request} request 
 * @returns {Promise<ApiResponse>}
 */
export async function api(request) {
    const response = await fetch(request);
    
    if (response.status === 401) {
        setLoggedOut();
    }

    const retval = {
        status: response.status,
        isOk: response.ok,
    }

    const responseData = await response.text();
    if (responseData.length > 0) {
        try {
            retval.data = JSON.parse(responseData);
        } catch (err) {
            console.log(`Error: Could not parse api response as json.`);
        }
    }

    return retval;
}

function buildUrl(urlString, options) {
    const url = new URL(urlString);
    for (const [paramName, value] of Object.entries(options)) {
        if (value) {
            url.searchParams.append(paramName, value);
        }
    }
    return url.href;
}

// URLs

export function booksUrl(options = {}) { return buildUrl(`${baseUrl}/api/books`, options); }
export function bookUrl(bookId, options = {}) { return buildUrl(`${booksUrl()}/${bookId}`, options); }

function chaptersUrl(bookId) { return `${bookUrl(bookId)}/chapters`; }
function chapterUrl(bookId, chapterId, options = {}) { return buildUrl(`${chaptersUrl(bookId)}/${chapterId}`, options); }

function sectionsUrl(bookId, chapterId) { return `${chapterUrl(bookId, chapterId)}/sections`; }
function sectionUrl(bookId, chapterId, sectionId, options = {}) {
    return buildUrl(`${sectionsUrl(bookId, chapterId)}/${sectionId}`, options);
}

function practiceRecommendationsUrl() { return `${baseUrl}/api/practice/recommendations`; }
function practiceRecommendationUrl(practiceRecommendationId) {
    return `${practiceRecommendationsUrl()}/${practiceRecommendationId}`;
}
function practiceRecommendationMarkSolvedUrl(practiceRecommendationId) {
    return `${practiceRecommendationUrl(practiceRecommendationId)}/solved`;
}
function practiceRecommendationMarkFailedUrl(practiceRecommendationId) {
    return `${practiceRecommendationUrl(practiceRecommendationId)}/failed`;
}
function practiceRecommendationsResetAndGetUrl() {
    return `${practiceRecommendationsUrl()}/reset-and-get`;
}

function practiceRecommendationsV2Url() { return `${baseUrl}/api/practice/recommendations-v2`; }
function practiceRecommendationV2Url(practiceRecommendationId) {
    return `${practiceRecommendationsV2Url()}/${practiceRecommendationId}`;
}
function practiceRecommendationV2MarkSolvedUrl(practiceRecommendationId) {
    return `${practiceRecommendationV2Url(practiceRecommendationId)}/solved`;
}
function practiceRecommendationV2MarkFailedUrl(practiceRecommendationId) {
    return `${practiceRecommendationV2Url(practiceRecommendationId)}/failed`;
}
function practiceRecommendationV2MarkDismissedUrl(practiceRecommendationId) {
    return `${practiceRecommendationV2Url(practiceRecommendationId)}/dismissed`;
}
function practiceRecommendationsV2ResetAndGetUrl() {
    return `${practiceRecommendationsV2Url()}/reset-and-get`;
}

function practiceHistoryItemsUrl() { return `${baseUrl}/api/practice/history-items`; }
const practiceHistoryItemsGetAllUrl = practiceHistoryItemsUrl;
function practiceHistoryItemUrl(itemId) { return `${practiceHistoryItemsUrl()}/${itemId}`; }
const practiceHistoryItemDeleteUrl = practiceHistoryItemUrl;
function practiceHistoryItemToHubUrl(itemId) { return `${practiceHistoryItemUrl(itemId)}/to-hub`; }
function practiceHistoryItemMarkSolvedUrl(itemId) { return `${practiceHistoryItemUrl(itemId)}/mark-solved`; }
function practiceHistoryItemMarkUnsolvedUrl(itemId) { return `${practiceHistoryItemUrl(itemId)}/mark-unsolved`; }
function practiceHistoryItemMarkDismissedUrl(itemId) { return `${practiceHistoryItemUrl(itemId)}/mark-dismissed`; }

function alertsUrl() { return `${baseUrl}/api/alerts`; }

function leetcodeQuestionSetsUrl() { return `${baseUrl}/api/leetcode-question-sets`; } // TODO: we should be able to delete this now.

/**
 * 
 * @param {SourceName} sourceName 
 * @returns {string}
 */
function sourceConfigUrl(sourceName) { return `${baseUrl}/api/sources/${sourceName}/config`; }

/**
 * 
 * @param {SourceName} sourceName 
 * @returns {string}
 */
function sourceConfigSetDifficultyUrl(sourceName) { return `${sourceConfigUrl(sourceName)}/setDifficulty`; }

/**
 * 
 * @param {SourceName} sourceName 
 * @returns {string}
 */
function sourceConfigTagDifficultyUrl(sourceName) { return `${sourceConfigUrl(sourceName)}/tagDifficulty`; }

/**
 * 
 * @param {SourceName} sourceName 
 * @returns {string}
 */
function sourceConfigExcludeUrl(sourceName) {
    return `${sourceConfigUrl(sourceName)}/exclude`;
}

/**
 * 
 * @param {SourceName} sourceName 
 * @returns {string}
 */
function sourceConfigIncludeUrl(sourceName) {
    return `${sourceConfigUrl(sourceName)}/include`;
}

// Request builders

// books requests

export function booksGetAllRequest({ includeOnlyKnows = false } = {}) {
    const options = {};
    if (includeOnlyKnows) {
        options.includeOnlyKnows = includeOnlyKnows
    }
    return request({ url: booksUrl(options) });
}

export function booksCreateRequest(bookName, isbn) {
    return request({
        url: booksUrl(),
        method: httpQueryType.post,
        body: { bookName: bookName, isbn: isbn },
    });
}

export function bookGetRequest(bookId, options = {}) {
    return request({ url: bookUrl(bookId, options) });
}

export function bookDeleteRequest(bookId) {
    return request({ url: bookUrl(bookId), method: httpQueryType.delete });
}

// book chapter requests

export function chapterCreateRequest(bookId, title) {
    return request({
        url: chaptersUrl(bookId),
        method: httpQueryType.post,
        body: {title: title},
    });
}

export function chapterGetRequest(bookId, chapterId,
    {
        includeSections = false,
        includeBook = false,
        includeSectionPracticeUnits = false,
        includeSectionKnows = false,
    }) {
    const options = {
        includeSections: includeSections,
        includeBook: includeBook,
        includeSectionPracticeUnits: includeSectionPracticeUnits,
        includeSectionKnows: includeSectionKnows,
    };
    return request({ url: chapterUrl(bookId, chapterId, options) });
}

export function chapterDeleteRequest(bookId, chapterId) {
    return request({
        url: chapterUrl(bookId, chapterId),
        method: httpQueryType.delete
    });
}

// book chapter section requests

/**
 * 
 * @param {number} bookId 
 * @param {number} chapterId 
 * @param {number} sectionId 
 * @param {{includePracticeUnits?: boolean, includeKnows?: boolean}} param3 
 * @returns 
 */
export function sectionGetRequest(bookId, chapterId, sectionId,
    { includePracticeUnits = undefined, includeKnows = undefined } = {}) {
    const options = { includePracticeUnits: includePracticeUnits, includeKnows: includeKnows }
    return request({ url: sectionUrl(bookId, chapterId, sectionId, options) });
}

export function sectionDeleteRequest(bookId, chapterId, sectionId) {
    return request({
        url: sectionUrl(bookId, chapterId, sectionId),
        method: httpQueryType.delete,
    });
}

export function sectionCreateRequest(bookId, chapterId, data) {
    return request({
        url: sectionsUrl(bookId, chapterId),
        method: httpQueryType.post,
        body: data,
    });
}

export function sectionUpdateRequest(bookId, chapterId, sectionId, data) {
    return request({
        url: sectionUrl(bookId, chapterId, sectionId),
        method: httpQueryType.put,
        body: data
    });
}

// Practice Recommendations

export function practiceRecommendationsGetAllRequest() {
    return request({ url: practiceRecommendationsUrl() });
}

export function practiceRecommendationsResetAndGetRequest() {
    return request({
        url: practiceRecommendationsResetAndGetUrl(),
        method: httpQueryType.post,
        body: {}
    });
}

export function practiceRecommendationMarkSolvedRequest(practiceRecommendationId) {
    return request({
        url: practiceRecommendationMarkSolvedUrl(practiceRecommendationId),
        method: httpQueryType.put,
        body: {}
    });
}

export function practiceRecommendationMarkFailedRequest(practiceRecommendationId) {
    return request({
        url: practiceRecommendationMarkFailedUrl(practiceRecommendationId),
        method: httpQueryType.put,
        body: {}
    });
}

// Practice Recommendations V2

export function practiceRecommendationsV2GetAllRequest() {
    return request({ url: practiceRecommendationsV2Url() });
}

export function practiceRecommendationsV2ResetAndGetRequest() {
    return request({
        url: practiceRecommendationsV2ResetAndGetUrl(),
        method: httpQueryType.post,
        body: {}
    });
}

export function practiceRecommendationV2MarkSolvedRequest(practiceRecommendationId) {
    return request({
        url: practiceRecommendationV2MarkSolvedUrl(practiceRecommendationId),
        method: httpQueryType.put,
        body: {}
    });
}

export function practiceRecommendationV2MarkFailedRequest(practiceRecommendationId) {
    return request({
        url: practiceRecommendationV2MarkFailedUrl(practiceRecommendationId),
        method: httpQueryType.put,
        body: {}
    });
}

export function practiceRecommendationV2MarkDismissedRequest(practiceRecommendationId) {
    return request({
        url: practiceRecommendationV2MarkDismissedUrl(practiceRecommendationId),
        method: httpQueryType.put,
        body: {}
    });
}

// Practice history

export function practiceHistoryItemsGetAllRequest() {
    return request({ url: practiceHistoryItemsGetAllUrl() });
}

export function practiceHistoryItemDeleteRequest(itemId) {
    return request({ url: practiceHistoryItemDeleteUrl(itemId), method: httpQueryType.delete })
}

export function practiceHistoryItemToHubRequest(itemId) {
    return request({ url: practiceHistoryItemToHubUrl(itemId), method: httpQueryType.put, });
}

export function practiceHistoryItemMarkSolvedRequest(itemId) {
    return request({ url: practiceHistoryItemMarkSolvedUrl(itemId), method: httpQueryType.put, });
}

export function practiceHistoryItemMarkUnsolvedRequest(itemId) {
    return request({ url: practiceHistoryItemMarkUnsolvedUrl(itemId), method: httpQueryType.put, });
}

export function practiceHistoryItemMarkDismissedRequest(itemId) {
    return request({ url: practiceHistoryItemMarkDismissedUrl(itemId), method: httpQueryType.put, });
}

// Source configs -- these are helpers for source requests for each source

/**
 * 
 * @param {SourceName} sourceName 
 * @returns {Request}
 */
export function sourceConfigGetRequest(sourceName) {
    return request({ url: sourceConfigUrl(sourceName) });
}

/**
 * 
 * @param {SourceName} sourceName 
 * @param {number} sourceId 
 * @param {number} setId 
 * @param {number} difficultyId 
 * @param {boolean} include 
 * @returns {Request}
 */
function sourceConfigSetDifficultyUpdateRequest(sourceName, sourceId, setId, difficultyId, include) {
    return request({
        url: sourceConfigSetDifficultyUrl(sourceName),
        method: httpQueryType.put,
        body: {
            sourceId: sourceId,
            setId: setId,
            difficultyId: difficultyId,
            include: include,
        },
    });
}

/**
 * 
 * @param {SourceName} sourceName 
 * @param {number} sourceId 
 * @param {number} tagId 
 * @param {number} difficultyId 
 * @param {boolean} include 
 * @returns {Request}
 */
function sourceConfigTagDifficultyUpdateRequest(sourceName, sourceId, tagId, difficultyId, include) {
    return request({
        url: sourceConfigTagDifficultyUrl(sourceName),
        method: httpQueryType.put,
        body: {
            sourceId: sourceId,
            tagId: tagId,
            difficultyId: difficultyId,
            include: include,
        },
    });
}

/**
 * 
 * @param {SourceName} sourceName 
 * @returns {Request}
 */
function sourceConfigExcludeRequest(sourceName) {
    return request({
        url: sourceConfigExcludeUrl(sourceName),
        method: httpQueryType.put,
        body: {},
    });
}

/**
 * 
 * @param {SourceName} sourceName 
 * @returns {Request}
 */
function sourceConfigIncludeRequest(sourceName) {
    return request({
        url: sourceConfigIncludeUrl(sourceName),
        method: httpQueryType.put,
        body: {},
    });
}

// Leetcode Config

export function leetcodeQuestionSetsUpdateRequest(data) {
    return request({
        url: leetcodeQuestionSetsUrl(),
        method: httpQueryType.put,
        body: data,
    });
}

export function leetcodeQuestionSetsGetRequest() {
    return request({ url: leetcodeQuestionSetsUrl() });
}

export function leetcodeConfigGetRequest() {
    return sourceConfigGetRequest('leetcode');
}

/** @type {QuestionSetDifficultyUpdateRequestFunction} */
export function leetcodeConfigSetDifficultyUpdateRequest(sourceId, setId, difficultyId, include) {
    return sourceConfigSetDifficultyUpdateRequest('leetcode', sourceId, setId, difficultyId, include);
}

/** @type {TagDifficultyUpdateRequestFunction} */
export function leetcodeConfigTagDifficultyUpdateRequest(sourceId, tagId, difficultyId, include) {
    return sourceConfigTagDifficultyUpdateRequest('leetcode', sourceId, tagId, difficultyId, include);
}

/** @type {ExcludeRequestFunction} */
export function leetcodeConfigExcludeRequest() {
    return sourceConfigExcludeRequest('leetcode');
}

/** @type {IncludeRequestFunction} */
export function leetcodeConfigIncludeRequest() {
    return sourceConfigIncludeRequest('leetcode');
}

// HackerRank Config

export function hackerrankConfigGetRequest() {
    return sourceConfigGetRequest('hackerrank');
}

/** @type {QuestionSetDifficultyUpdateRequestFunction} */
export function hackerrankConfigSetDifficultyUpdateRequest(sourceId, setId, difficultyId, include) {
    return sourceConfigSetDifficultyUpdateRequest('hackerrank', sourceId, setId, difficultyId, include);
}

/** @type {TagDifficultyUpdateRequestFunction} */
export function hackerrankConfigTagDifficultyUpdateRequest(sourceId, tagId, difficultyId, include) {
    return sourceConfigTagDifficultyUpdateRequest('hackerrank', sourceId, tagId, difficultyId, include);
}

/** @type {ExcludeRequestFunction} */
export function hackerrankConfigExcludeRequest() {
    return sourceConfigExcludeRequest('hackerrank');
}

/** @type {IncludeRequestFunction} */
export function hackerrankConfigIncludeRequest() {
    return sourceConfigIncludeRequest('hackerrank');
}

// Alerts

export function alertsGetAllRequest() {
    return request({ url: alertsUrl(), });
}