// @ts-check

import React from "react";
import { Link } from "react-router-dom";
import { capitalize } from "../helpers/others.js";
    
/**
 * 
 * @param {{question: import("../api").FemQuestion}} param0 
 * @returns 
 */
export default function QuestionDescription({question}) {
    return (
        <>
            <small>
                <strong>[{capitalize(question.source.name)}]</strong>
                &nbsp;
                <strong>[{capitalize(question.difficulty)}]</strong>
            </small>&nbsp;
            <span className="is-hidden-desktop"><br /></span>
            <Link to={question.url}
                target="_blank"
                rel="noopener noreferrer">
                    {question.name}
            </Link><br />
            <small>
                <strong>QuesitonSets:</strong>&nbsp;
                {question.questionSets.length === 0
                    ? "-"
                    : question.questionSets.map((set) => set.name).join(', ')}<br />
                <strong>Tags:</strong>&nbsp;
                {question.tags.length === 0
                    ? "-"
                    : question.tags.map((tag) => tag.name).join(', ')}<br />
            </small>
        </>
    );
}