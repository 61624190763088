// @ts-check

/** @typedef {import("../../../api/front-end-types.js").FemSource} FemSource */

import { useLoaderData } from "react-router-dom";
import {
    leetcodeConfigExcludeRequest,
    leetcodeConfigIncludeRequest,
    leetcodeConfigSetDifficultyUpdateRequest,
    leetcodeConfigTagDifficultyUpdateRequest } from "../api";
import SourceConfigPage from "../components/source-config";
import React from "react";

export default function SourceLeetcode() {
    /** @type {FemSource} */
    // @ts-ignore
    const source = useLoaderData();

    return (
        <SourceConfigPage
            sourceName={source.name}
            sourceId={source.sourceId}
            dataProp={source.isSourceIncluded ? source.config : undefined}
            questionSetDifficultyUpdateRequestFunction={leetcodeConfigSetDifficultyUpdateRequest}
            tagDifficultyUpdateRequestFunction={leetcodeConfigTagDifficultyUpdateRequest}
            includeRequestFunction={leetcodeConfigIncludeRequest}
            excludeRequestFunction={leetcodeConfigExcludeRequest}
        />
    );
}
