import { Link } from "react-router-dom";
import paths from "../paths";

export default function Sources() {
    return (
        <>
            <h1>Sources</h1>
            <ul>
                <li>
                    <Link to={paths.book.getAll}>Books</Link>
                </li>
                <li>
                    <Link to={paths.leetcodeQuestionSets}>Leetcode</Link>
                </li>
            </ul>
        </>
    );
}