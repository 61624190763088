// @ts-check

import { paths as basePaths } from 'react-base';

const paths = {
    base: basePaths,
    book: {
        create: "/books/new",
        get: "/books/:id",
        getAll: "/books",
        
        chapter: {
            create: "/books/:bookId/chapters/new",
            get: "/books/:bookId/chapters/:chapterId",
            
            section: {
                create: "/books/:bookId/chapters/:chapterId/sections/new",
                edit: "/books/:bookId/chapters/:chapterId/sections/:sectionId/edit",
            }
        }
    },
    knows: "/knows",
    leetcode: "/leetcode",
    hackerrank: "/hackerrank",
    leetcodeQuestionSets: "/leetcode-question-sets",  // leetcode v1
    practice: {
        hub: "/practice/hub",
        hubV2: "/practice/hubV2",
        history: {
            getAll: "/practice/history",
        },
    },
    sources: "/sources",
    about: "/about",
}

export function path(pathPattern, options = {}) {
    let retval = pathPattern;
    for (const [paramName, value] of Object.entries(options)) {
        if (value) {
            retval = retval.replaceAll(":" + paramName, String(value));
        }
    }
    return retval;
}

export default paths;