// @ts-check

import React, { useState } from "react";
import { ActionButton, useApi } from "react-base";
import ChangeModal from "./change-modal.js";

/** @typedef {(historyItemId: number, newStatus: import("../../api").FemHistoryItemStatus) => void} ChangeButtonOnChangeCallback */

/**
 * @typedef {Object} ChangeHistoryItemButtonProps
 * @prop {number} historyItemId
 * @prop {import("../../api").FemHistoryItemStatus} historyItemStatus
 * @prop {ChangeButtonOnChangeCallback} onChange
 */

/**
 * 
 * @param {ChangeHistoryItemButtonProps} param0 
 * @returns {React.JSX.Element}
 */
export default function ChangeButton({historyItemId, historyItemStatus, onChange}) {
    const [isModalShowing, setIsModalShowing] = useState(false);
    
    return (
        <>
            <ChangeModal
                isShowing={isModalShowing}
                setIsShowing={setIsModalShowing}
                historyItemId={historyItemId}
                currentStatus={historyItemStatus}
                onChange={onChange}
            />
            <button className="button is-responsive" onClick={(e) => setIsModalShowing(true)}>Change</button>
        </>
    )
}