import { useState } from 'react';
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { sectionUpdateRequest } from "../api";
import { useApi } from 'react-base';

export default function BookChapterSectionsEdit() {
    const navigate = useNavigate();
    const fetchFromApi = useApi();
    const sectionFromApi = (useLoaderData() || null); // this is assumed here to include book and sections
    let numberOfQuestionsFromApi = "";
    const sectionInitialValue = {
        title: sectionFromApi.title,
        numberOfQuestions: numberOfQuestionsFromApi,
        knows: (sectionFromApi.knows ? true : false),
    }
    console.log(sectionInitialValue);
    const [section, setSection] = useState(sectionInitialValue);
    const [errors, setErrors] = useState({});
    const { bookId, chapterId, sectionId } = useParams();

    if (sectionFromApi.practice_units && sectionFromApi.practice_units.length === 1) {
        const practiceUnit = sectionFromApi.practice_units[0];
        numberOfQuestionsFromApi = practiceUnit.question_range_end - practiceUnit.question_range_start + 1;
    } else {
        if (sectionFromApi.practice_units.length > 1) {
            navigate(`/books/${bookId}/chapters/${chapterId}`, { replace: true });
        }
    }

    console.log(`bookId: ${bookId} chapterId: ${chapterId} sectionId: ${sectionId}`);
    console.log(section);

    async function handleSubmit(event) {
        event.preventDefault(); // this prevents browser to send a request to the server

        // validate
        const newErrors = {};
        if (!section.title) {
            newErrors.title = "Section title is required.";
        }
        if (section.numberOfQuestions && isNaN(Number(section.numberOfQuestions))) {
            newErrors.numberOfQuestions = "Number of exercise questions must be a number.";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        // send to server
        const sectionToSend = {
            title: section.title,
            knows: section.knows,
        }
        if (section.numberOfQuestions) {
            sectionToSend.question_range_start = "1";
            sectionToSend.question_range_end = section.numberOfQuestions;
        } else {
            sectionToSend.question_range_start = null;
            sectionToSend.question_range_end = null;
        }
        console.log(sectionToSend);
        const response = await fetchFromApi(sectionUpdateRequest(bookId, chapterId, sectionId, sectionToSend));

        if (!response.isOk) {
            newErrors.result = "Error saving the section: " + JSON.stringify(response);
            setErrors(newErrors);
            return;
        }

        // return to chapter page and refresh data if successful, display error and stay if not
        navigate(`/books/${bookId}/chapters/${chapterId}`, { replace: true })
    }

    return (
        <div>
            {errors.result && <p>{errors.result}</p>}
            <br />
            <h1>Edit Section</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="title">Title (*):</label>
                    <input
                        type="text"
                        id="title"
                        value={section.title}
                        onChange={(event) => setSection({...section, title: event.target.value})}
                    />
                    {errors.title && <p>{errors.title}</p>}
                <br />

                <label htmlFor="num_questions">Number of questions:</label>
                <input
                    type="text"
                    id="num_questions"
                    value={section.numberOfQuestions || ""}
                    onChange={(event) => setSection({...section, numberOfQuestions: event.target.value})}
                />
                {errors.title && <p>{errors.title}</p>}
                <br />

                <input
                    type="checkbox"
                    id="knows"
                    name="knows"
                    checked={section.knows}
                    onChange={(event) => setSection({...section, knows: String(event.target.checked)})}
                />
                <label htmlFor="knows"> I know this</label>
                {errors.knows && <p>{errors.knows}</p>}
                <br />

                <button type="submit">Save</button>
            </form>
            <p>(*) Required.</p>
        </div>
    )
}