import { Link, useLoaderData } from "react-router-dom";
import paths, { path } from "../paths";
import {
    practiceRecommendationMarkFailedRequest,
    practiceRecommendationMarkSolvedRequest,
    practiceRecommendationsResetAndGetRequest,
} from "../api";
import { useState } from "react";

export default function PracticeHub() {
    const [errors, setErrors] = useState({});
    const practiceRecommendations = useLoaderData();
    const [recommendations, setRecommendations] = useState(practiceRecommendations);

    function removeRecommendation(id) {
        const newErrors = {};
        const index = recommendations.findIndex((rec) => rec.id === id);
        if (index === -1) {
            // Marked a recommendation as solved, but it's not in the list. Something fishy is going on.
            newErrors.result = "Unexpected error."
            setErrors(newErrors);
            return;
        } else {
            const newRecommendations = recommendations.toSpliced(index, 1);
            setRecommendations(newRecommendations);
        }
    }

    async function markSolved(event, practiceRecommendationId) {
        event.stopPropagation();
        const response = await fetch(practiceRecommendationMarkSolvedRequest(practiceRecommendationId));

        const newErrors = {}
        if (!response.ok) {
            newErrors.result = "Error marking the item as solved: " + JSON.stringify(response);
            setErrors(newErrors);
            return;
        }

        removeRecommendation(practiceRecommendationId);
    }

    async function markFailed(event, practiceRecommendationId) {
        event.stopPropagation();
        const response = await fetch(practiceRecommendationMarkFailedRequest(practiceRecommendationId));

        const newErrors = {}
        if (!response.ok) {
            newErrors.result = "Error marking the item as not solved: " + JSON.stringify(response);
            setErrors(newErrors);
            return;
        }

        removeRecommendation(practiceRecommendationId);
    }

    async function recalculateAndReload(event) {
        event.stopPropagation();

        fetch(practiceRecommendationsResetAndGetRequest())
        .then((response) => {
            const newErrors = {};
            if (!response.ok) {
                newErrors.result = "Error refreshing recommendations."
                setErrors(newErrors);
                return;
            }
            return response.json();
        })
        .then((newRecommendations) => setRecommendations(newRecommendations));
    }

    function formatRecommendationDescription(rec) {
        if (rec.section) {
            return (
                <>
                    Question {rec.section.question} in<br />
                    &nbsp;&nbsp;Book: {rec.section.book.name}<br />
                    &nbsp;&nbsp;Chapter: {rec.section.chapter.title}<br />
                    &nbsp;&nbsp;Section: <Link to={path(paths.book.chapter.section.edit, {bookId: rec.section.book.id, chapterId: rec.section.chapter.id, sectionId: rec.section.section.id})} >{rec.section.section.title}</Link>
                </>
            );
        } else if (rec.leetcodeQuestion) {
            return (
                <>
                    Leetcode question: <Link to={rec.leetcodeQuestion.url} target="_blank" rel="noopener noreferrer">{rec.leetcodeQuestion.title}</Link>
                </>
            );
        } else {
            return undefined;
        }
    }

    function formatTableRow(pr) {
        const description = formatRecommendationDescription(pr);
        if (description) {
            return (
                <tr>
                    <td>
                        {formatRecommendationDescription(pr)}
                    </td>
                    <td className="has-text-right is-narrow">
                        <div className="buttons has-addons is-right">
                            <button className="button" onClick={(event) => {markSolved(event, pr.id)}}>Solved</button>
                            {/* <a className="button" onClick={() => {markSolved(pr.id)}}>Solved</a> */}
                            {/* </td>
                            <td> */}
                            <button className="button" onClick={(event) => {markFailed(event, pr.id)}}>Couldn't Solve</button>
                        </div>
                    </td>
                </tr>
            );
        } else {
            return '';
        }
    }

    return (
        <>
            {errors.result && <><p>{errors.result}</p><br/></>}
            <p>
                <small>Here is a list of practice recommendations curated for you from the sources you selected.<br />
                As you practice and mark them as solved or not solved, this list will update to help you continue practicing with the best next question.
                <br/>
                <strong>Perfect practice makes perfect. Have fun!</strong>
                </small>
            </p>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <th></th>
                    <th className="is-narrow">
                        <button className="button is-fullwidth"
                                onClick={(event) => {recalculateAndReload(event)}}>
                            Refresh Recommendations
                        </button>
                    </th>
                </thead>
                <tbody>
                    {recommendations.map((pr) => formatTableRow(pr))}
                </tbody>
            </table>
        </>
    );
}