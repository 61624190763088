// @ts-check

import menu from "./menu.js";

/** @type {{appVisibleName: string, menu: import("react-base").Menu}} */
const appConfig = {
    appVisibleName: "RetainStation",
    menu: menu,
} 

export default appConfig;