// @ts-check

/**
 * Capitalize first letter of the given string
 * 
 * @param {string} s 
 * @returns {string} s, but first letter capitalized
 */
export function capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * 
 * @param {number} ms 
 * @returns {Promise<void>}
 */
export async function sleep(ms) {
    return new Promise((resolve, _) => {
        setTimeout(() => resolve(), ms);
    })
}