// @ts-check

import React from 'react';
import paths from '../paths.js';
import { Link } from 'react-router-dom';

export default function About() {
    return (
        <>
            <h1 className="title">What is RetainStation?</h1>
            <p>RetainStation is the tool for retaining what you learned. It is a tool for humans, who in time forget
                what they learned, unless they practice.</p>
            <p>RetainStation is your practicing helper. Its job is to make sure you don't go without practicing any 
                subjects you want to practice, and allows you to focus your time practicing instead of figuring out 
                what would be best to practice next.</p>
            <p>You can select what you want to practice using the sources 
                (e.g. <Link to={paths.leetcode}>leetcode</Link> , <Link to={paths.hackerrank}>hackerrank</Link>, 
                and soon others). Currently there are a few, but the list will grow.</p>
            <p>Once you select your sources, head to the <Link to={paths.practice.hubV2}>practice hub</Link>. There 
                you will find what RetainStation picked for you to practice next, to make sure that you are covering
                everything that you selected from the sources. Follow the suggested sequence as much as you can, and
                give RetainStation feedback on whether you solved or could not solve the questions so that 
                RetainStation can adapt its recommendations to what you practiced, and what you still need to practice.
            </p>
            <p>The goal is to spend time practicing on a regular basis. Create a schedule for yourself: for example
                around 30 mins every-day, or an hour every two days. Without spending much time, you will soon find
                that your knowledge is staying ever-fresh.</p>
            <p>We hope you will find RetainStation useful. If you have any feedback you want to share with us, any
                problems you want to report, or just share your thoughts, head to 
                our <Link to={paths.base.feedback}>feedback page</Link>. We love hearing from you.
            </p>
        </>
    );
}