// @ts-check

import React from "react";
import { ActionButton, useApi } from "react-base";
import { practiceHistoryItemDeleteRequest, practiceHistoryItemToHubRequest } from "../../api";

/** @typedef {(historyItemId: number) => void} MoveToHubButtonOnMoveCallback */

/**
 * @typedef {Object} MoveToHubButtonProps
 * @prop {number} historyItemId
 * @prop {MoveToHubButtonOnMoveCallback} [onMove]
 */

/**
 * 
 * @param {MoveToHubButtonProps} param0 
 * @returns {React.JSX.Element}
 */
export default function ToHubButton({historyItemId, onMove = undefined}) {
    const fetchFromApi = useApi();
    
    /** @type {import("react-base").ActionButtonClickHandler} */
    async function clickHandler(callback) {
        const response = await fetchFromApi(practiceHistoryItemToHubRequest(historyItemId));
        if (response.isOk) {
            if (onMove) {
                onMove(historyItemId);
            }
        }
        callback();
    }

    return (
        <ActionButton onClick={clickHandler} additionalButtonStyles="is-responsive">Add to hub</ActionButton>
    )
}