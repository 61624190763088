import { Link, useLoaderData, useNavigate } from "react-router-dom"
import paths, { path } from "../paths";

export default function Knows() {
    const books = (useLoaderData() || {}).books || [];
    console.log(JSON.stringify(books));
    const navigate = useNavigate();

    function addButtonOnClick() {
        navigate('/knows/new', { replace: true });
    }

    return (
        <>
            <p>You already know these.</p>
            <button onClick={addButtonOnClick}>Add new</button>
            <table>
                <tr>
                    <th>Book</th>
                    <th>Chapter</th>
                    <th>Section</th>
                </tr>
                {books.map((book) => 
                    book.chapters.map((chapter, chapterIndex) =>
                        chapter.sections.map((section, sectionIndex) => (
                            <tr>
                                {(chapterIndex === 0) && (sectionIndex === 0) && (
                                    <td rowspan={book.number_of_sections} style={{border: "1px solid black"}}>
                                        <Link
                                            to={
                                                path(paths.book.get,
                                                    {
                                                        id: book.id,
                                                    })
                                                }
                                        >{book.name}</Link>
                                    </td>
                                )}
                                {(sectionIndex === 0) && (
                                    <td rowspan={chapter.number_of_sections} style={{border: "1px solid black"}}>
                                        <Link
                                            to={
                                                path(paths.book.chapter.get,
                                                    {
                                                        bookId: book.id,
                                                        chapterId: chapter.id,
                                                    })
                                                }
                                        >{chapter.title}</Link>
                                    </td>
                                )}
                                <td style={{border: "1px solid black"}}>
                                    <Link
                                        to={
                                            path(paths.book.chapter.section.edit,
                                                {
                                                    bookId: book.id,
                                                    chapterId: chapter.id,
                                                    sectionId: section.id
                                                })
                                            }
                                    >{section.title}</Link>
                                </td>
                            </tr>
                        ))
                    )
                )}
            </table>
        </>
    )
}